;(function ($) {

  'use strict';

  var modal = {
    init: function() {
      $('body').on('click', '.modalLink', function(e) {
        e.preventDefault();
        // get id
        var id = $(this).attr('href');
        if ($(id).length) {
          $(id).addClass('modal--active');
          $('body').addClass('modal-active');
        }
      });

      // close registration
      this.close();
    },

    close: function() {

      var $modals = $('.modal:not(.modal--bootstrap)');

      $('.modal__close, .modal:not(.modal--bootstrap)').on('click', function(e) {
        e.preventDefault();

        $modals.removeClass('modal--active');
        $('body').removeClass('modal-active');
      });

      // stop propagation on modal wrap
      $('.modal__container').on('click', function(e) {
        e.stopPropagation();
      });

      // on escape
      $(document).keyup(function(e) {
        if (e.keyCode == 27) { // escape key maps to keycode `27`

          $modals.removeClass('modal--active');
          $('body').removeClass('modal-active');

        }
      });
    },
  };

  modal.init();

})(jQuery);