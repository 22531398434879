

// menu scripts
;(function ($) {

  "use strict";

  // $('#menuButton').on('click', function(e) {
  //   $('body').toggleClass('menu-active');
  // });

  // $(document).on('click', function(e) {
  //   if (!$(e.target).closest('.header').length && !$(e.target).closest('#menuButton').length) {
  //     $('body').removeClass('menu-active');
  //   }
  // });

  $('.nav__toggle').on('click', function(e) {
    e.preventDefault();
    $('.nav__toggle').toggleClass('is-active');
    $('body').toggleClass('menu-active');

    // schovat search
    $('#search-form-expandable-container').addClass('d-none');
    $('#searchBtn').removeClass('active');
  });

  $(document).on('click', function(e) {
    if (!$(e.target).closest('.header').length && !$(e.target).closest('.nav__toggle').length) {
      $('.nav__toggle').removeClass('is-active');
      $('body').removeClass('menu-active');
    }
  });

  // $('.no-submenu').on('mouseover', function(e) {
  //   $('.header__nav').find('.nav__list > li.current').addClass('no-submenu-active');
  // });

  // $('.no-submenu').on('mouseover', function(e) {
  //   $('.header__nav').find('.nav__list > li.current').addClass('no-submenu-active');
  // });

  var $navList = $('.header__nav').find('.nav__list');
  $('.no-submenu').hover(
    function() {       
      $navList.find('> li.current').addClass('no-submenu-active');
    },
    function() {            
      $navList.find('> li.current').removeClass('no-submenu-active');
    }
  );

  // $('#navPrimary').on('mouseenter','.nav__item', function(){
    
  //   $('.nav__item').removeClass('active');
  //   $(this).addClass('active');

  //   var $subnav = $('#' + $(this).attr('data-id'));
  //   if ($subnav.length) {
  //     $subnav.addClass('active');
  //   }

  // }).on('mouseleave','.nav__item', function(){
    
  //   $('.nav__item').removeClass('active');
  //   console.log('mouseleave');
    
  //   var $subnav = $('#' + $(this).attr('data-id'));
  //   if ($subnav.length) {
  //     if ($subnav.hasClass('nav__submenu-default-visible')) {
  //       $subnav.addClass('active');
  //       $(this).addClass('active');
  //     }
  //     else {
  //       // $subnav.removeClass('active');
  //     }
  //   }

  // });

  // $('.nav__submenu').on('mouseenter', function(){

  //   console.log('submenenu over')
  //   $(this).addClass('active');

  //   var $nav = $(`.nav__item[data-id="${$(this).attr('id')}"]`);
  //   $nav.addClass('active');


  // }).on('mouseleave', function() {


  //   console.log('submenu leaver')

  //   if ($(this).hasClass('nav__submenu-default-visible')) {
  //     $(this).addClass('active');
  //   }
  //   else {
  //     $(this).removeClass('active');
  //     $('.nav__item').removeClass('active');
  //   }

  // });


})(jQuery);
