window.pushAuthorProductImpressions = function(productIds, authorIds, displayType) {
    $.ajax({
        method: 'post',
        url: '/katalog/authors/log-impression',
        data: {
            productIds: productIds,
            authorIds: authorIds,
            displayType: displayType,
            url: window.location.href,
            _token: document.querySelector('meta[name="csrf-token"]').content,
        },
    }).done(function(a, b) {
        console.log(a, b);
    }).fail(function(a, b) {
        console.error(a, b);
    })
}
