window.pushJobOfferImpressions = function(ids, displayType) {
    $.ajax({
        method: 'post',
        url: '/job-offers/log-impression',
        data: {
            jobOfferIds: ids,
            displayType: displayType,
            url: window.location.href,
            _token: document.querySelector('meta[name="csrf-token"]').content,
        },
    }).done(function(a, b) {
        console.log(a, b);
    }).fail(function(a, b) {
        console.error(a, b);
    })
}
