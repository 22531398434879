;(function ($) {

	'use strict';

	$('#searchBtn').on('click', function(e) {
		$(this).toggleClass('active');
    $('#search-form-expandable-container').toggleClass('d-none');

		// schovat menu
		$('.nav__toggle').removeClass('is-active');
		$('body').removeClass('menu-active');
	});

	// on escape
	$(document).keyup(function(e) {
		if (e.keyCode == 27) { // escape key maps to keycode `27`
			$('#search-form-expandable-container').addClass('d-none');
			$('#searchBtn').removeClass('active');
		}
	});

	$(document).on('click', function(e) {
		if (!$(e.target).closest('.searchComponent').length && !$(e.target).closest('#searchBtn').length) {
			$('#search-form-expandable-container').addClass('d-none');
			$('#searchBtn').removeClass('active');

		}
	});

	// reset buttons

	// dates
	var $dates = $('#searchDate1, #searchDate2');
	var $resetBtnDates = $('.reset--dates');
	$dates.on('change', function(e) {
		console.log('Search - date is changed');
		$resetBtnDates.removeClass('d-none');
	});

	$resetBtnDates.on('click', function(e) {
		$dates.val('');
		$(this).addClass('d-none');
	});

	// categories
	var $resetBtnCategories = $('.reset--categories');
	var $searchCategories = $('#searchCats').find('input');
	var searchCategoriesCount = $searchCategories.length;
	var searchCategoriesCountChecked = $('#searchCats').find('input:checked').length;

	if (searchCategoriesCount == searchCategoriesCountChecked) {
		$resetBtnCategories.removeClass('d-none');
	}

	$searchCategories.on('change', function(e) {
		searchCategoriesCountChecked = $('#searchCats').find('input:checked').length;
		if (searchCategoriesCount == searchCategoriesCountChecked) {
			$resetBtnCategories.removeClass('d-none');
		}
		else {
			$resetBtnCategories.addClass('d-none');
		}
	});

	$resetBtnCategories.on('click', function(e) {
		$searchCategories.prop('checked', false);
		$(this).addClass('d-none');
	});

	// location
	var $location = $('#searchRegion, #searchCity');
	var $resetBtnLocation = $('.reset--location');
	$location.on('change', function(e) {
		console.log('Search - location is changed');
		$resetBtnLocation.removeClass('d-none');
	});

	$resetBtnLocation.on('click', function(e) {
		$location.val('');
		$(this).addClass('d-none');
	});

	// final year
	var $year = $('#searchYear');
	var $resetBtnYear = $('.reset--year');
	$year.on('change', function(e) {
		console.log('Search - final year is changed');
		$resetBtnYear.removeClass('d-none');
	});

	$resetBtnYear.on('click', function(e) {
		$year.val('');
		$(this).addClass('d-none');
	});

})(jQuery);
