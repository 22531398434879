;(function ($) {

	'use strict';

	// nejdrive musim vložit <a> tag obrázkům
	// var $ckImages = $('.ck-articleImage img');
	// if ($ckImages.length) {
	// 	$ckImages.each(function(i) {
	// 		// console.log($(this));
	// 		$(this).wrap(`<a class="modalLink modalLink--gallery" href="#galleryModal" data-index="${i}"></a>`);
	// 	});
	// }

	var $galleryCaptions = $('.gallery-detail__caption');

	var $galleryCarousel = $('#galleryCarousel');
	if ($galleryCarousel.length) {
		$galleryCarousel.slick({
			lazyLoad: 'ondemand',
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			speed: 500,
			fade: true,
			cssEase: 'linear',
		});

		$galleryCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			// $galleryCaptions.hide(500);
			// $($galleryCaptions[nextSlide]).show(500);
			$galleryCaptions.hide();
			$($galleryCaptions[nextSlide]).show();
		});
	}

	var $galleryBottom = $('.gallery--bottom');
	if ($galleryBottom.length) {
		$('.gallery__showAll').on('click', function(e) {
			$galleryBottom.find('.gallery__showAll').addClass('d-none');
			$galleryBottom.find('.gallery__thumb').removeClass('d-none');
		});
	}

	var $galleryModal = $('#galleryModal');
	var $galleryItemsMobile = $('.gallery-detail__item-mobile');
	if ($galleryModal.length) {
		$('.gallery__thumb, .modalLink--gallery').on('click', function(e) {
			e.preventDefault();

			var index = $(this).attr('data-index');
			// console.log(index);

			// get the carousel__item
			var $carouselItem = $galleryModal.find('.carousel__item[data-index="' + index + '"]');
			var dataSlickIndex = parseInt($carouselItem.closest('.slick-slide').attr('data-slick-index'));
			// console.log(dataSlickIndex);

			$galleryCarousel.slick('slickGoTo', dataSlickIndex);


			$galleryModal.addClass('modal--active');
			$('body').addClass('modal-active');

			$galleryCarousel.find('.slick-list').attr('tabindex', 0).focus();

			// mobile
			// scroll to viewport
			$galleryItemsMobile[dataSlickIndex].scrollIntoView({
				behavior: 'smooth',
			});

		});
	}

})(jQuery);