
// import libs
import './libs/cookieBar';

var $header = $('#header');
var headerHeight = $header.outerHeight();

// import components
import './components/ie-detection.js';
import './components/menu.js';
import './components/job-offers.js';
import './components/authors-products.js';
import './components/gallery.js';
import './components/modal.js';
import './components/search.js';

// init
// $('.lazy').Lazy({
//     // your configuration goes here
//     scrollDirection: 'vertical',
//     effect: 'fadeIn',
//     effectTime: 150,
//     visibleOnly: true,
//     threshold: 500,
//     bind: 'event',
//     onError: function(element) {
//         console.log('error loading ' + element.data('src'));
//     }
// });

// ----------------------------------------
//  scroll to
var $scrollToItems = $('.scrollTo');
$.each($scrollToItems, function() {
    $(this).on('click', function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        if (typeof href === 'undefined') {
            href = $(this).attr('data-href');
        }
        var offset = $(this).attr('data-offset');

        // if booking strip scroll to
        if ($(this).hasClass('scrolling-strip')) {
            const mq = window.matchMedia( "(max-width: 576px)" );
            if (mq.matches) {
                offset = 0;
            }
        }

        var $target = $(href);
        if ($target.length) {
            $('html,body').animate({
                scrollTop: $target.offset().top - headerHeight - ((typeof offset !== 'undefined') ? parseInt(offset) : 0)
            }, {
                duration: 300,
                easing: 'swing'
            });
        }
    });
});
// ----------------------------------------

// scripts here
// ----------------------------------------

// Bootstrap dropdown
// $('.dropdown-toggle').dropdown();

// collapse
// $('.collapse__toggle, .collapse__toggle > label').on('click', function(e) {
//     $(this).closest('.collapse').toggleClass('show');
// });

// modal welcome
var $modalWelcome = $('#modalWelcome');
if ($modalWelcome.length && !Cookies.get('welcome-modal-showed')) {
    $modalWelcome.modal('show');
    Cookies.set('welcome-modal-showed', 1, { expires: 999 });
}

var $modalNewsletter = $('#modalNewsletter');
if ($modalNewsletter.length) {
    if (Cookies.get('register-popup') != 1) {

        $('html').bind("mouseleave", function (event) {

            if (!$(event.target).hasClass('form-control')) {
                $modalNewsletter.modal('show');
                $('html').unbind("mouseleave");

                Cookies.set('register-popup', 1, { expires: 180 });
            }

        });

        $(document).on('click', function(e) {
            if (!$(e.target).closest('.modal-content').length) {
                $modalNewsletter.modal('hide');
            }
        });

        $(document).keyup(function(e) {
        if (e.keyCode == 27) { // escape key maps to keycode `27`
                $modalNewsletter.modal('hide');
            }
        });

    }
}


// catalog cloud / carousel
$('.carousel--catalog').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,    
    variableWidth: true, 
    dots: false,
    arrows: true,
    responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
            vertical: true,
            variableHeight: true, 
            verticalSwiping: true,
            infinite: false,
            variableWidth: false,
          }
        },
    ],
});